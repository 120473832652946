<template>
    <div class="page">
      <img class="topImg pointer" src="../assets/img/hone_banner.jpg" @click="toAgent">
      <div class="contentView">
        <div class="product">
          <div class="productMenu">
            <img class="menuItem pointer"
              v-for="(item,index) in products"
              :key="item.id"
              :src="item.menuImg"
              @click="menuItemTouch(index)">
          </div>
          <img class="productMenuLine" :style="{left: 12.5*productSel+'%'}" src="../assets/img/product_menu_line.png">
          <img class="deviceImg" :src="products[productSel].deviceImg">
        </div>
        <div class="trends">
          <div class="trendsItem pointer" v-for="item in trends" :key="item.title" @click="trendsTouch(item.code)">
            <img :src="item.img">
            <span>{{item.title}}</span>
            <div class="line"></div>
          </div>
          <div class="trendsOptionBg">
            <div style="font-size:50px;">DYANMICS</div>
            <div style="font-size:33px; margin:15px 0 40px 0;">公司动态</div>
              <div class="trendsOption pointer"
                v-for="item in trends"
                :key="item.title"
                @click="trendsTouch(item.code)"
              >{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {Carousel,CarouselItem} from 'element-ui'
Vue.use(Carousel)
Vue.use(CarouselItem)
function getProducts(){
  let products = [];
  for(var i=0;i<8;i++){
    products.push({
      menuImg: require(`../assets/img/product_menu_${i+1}.png`),
      deviceImg: require(`../assets/img/product_img_${i+1}.jpg`)
    })
  }
  return products;
}
export default {
  data () {
    return {
      products: getProducts(),
      productSel: 0,
      trends:[
        {
          title: '公司动态',
          code: 'trends',
          img: require('../assets/img/1_3.jpg')
        },
        {
          title: '品牌合作',
          code: 'collaborate',
          img: require('../assets/img/1_4.jpg')
        },
        {
          title: '速绿-永不止步',
          code: 'sulv',
          img: require('../assets/img/1_5.jpg')
        },
      ],
      trendsSel: 0
    };
  },
  components: {},
  mounted () {
    let products = [];
    for(var i=0;i<8;i++){
      products.push({
        menuImg: require(`../assets/img/product_menu_${i+1}.png`),
        deviceImg: require(`../assets/img/product_img_${i+1}.jpg`)
      })
    }
    this.products = products;
  },
  methods: {
    menuItemTouch(index){
      this.productSel = index;
    },
    trendsTouch(code){
      this.$router.push({
          path: 'trends',
          query: { code }
        })
    },
    toAgent(){
      this.$router.push('agent');
    },
  }
}
</script>

<style lang='scss' scoped>
.pointer {
  cursor: pointer;
}
.page{
  width: 100%;
  font-weight: 500;
  margin: 0 auto;
}
.topImg{
  width: 100%;
  height: auto;
}
.contentView{
  width: 1200px;
  margin: auto;
}
.product{
  margin-top: 40px;
  position: relative;
  .productMenu{
    .menuItem{
      width: 12.5%;
    }
  }
  .productMenuLine{
    position: absolute;
    width: 12.5%;
    height: 10px;
    transition: all 0.2s;
  }
  .deviceImg{
    width: 100%;
    margin-top: 40px;
  }
}
.trends{
  margin: 150px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .trendsItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
    img{
      width: 100%;
      height: auto;
    }
    span{
      margin: 20px 0 5px 0;
    }
    .line{
      width: 70px;
      height: 4px;
      background: #2EB94F;
    }
  }
  .trendsOptionBg{
    text-align: left;
    .trendsOption{
      line-height: 40px;
      border: 1px solid #2EB94F;
      color: #2EB94F;
      margin-top: 15px;
      padding-left: 20px;
      font-size: 15px;
    }
    .trendsOption:hover{
      background: #2EB94F;
      color: white;
    }
  }
}
</style>